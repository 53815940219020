// https://bitbucket.org/cmcqueen1975/htmlfloatingtableheader/wiki/Home

(function() {
	var topOffset = 58;

	function UpdateTableHeaders() {
		$("table.header-float").each(function() {
			$(this).removeClass('header-float')
			$(this).wrap("<div class=\"header-float\" style=\"position:relative\"></div>");

			var originalHeaderRow = $("tr:first", this)
			originalHeaderRow.before(originalHeaderRow.clone());

			var clonedHeaderRow = $("tr:first", this)
				.addClass("header-floating")
				.css("position", "fixed")
				.css("top", 0)
				.css("visibility", "hidden")
				.css("z-index", 100)
				.css("background", $(this).find('thead').css('background'))

			originalHeaderRow.addClass("header-original");
		});

		$("div.header-float").each(function() {
			var originalHeaderRow = $(".header-original", this);
			var floatingHeaderRow = $(".header-floating", this);
			var offset = $(this).offset();
			var scrollTop = $(window).scrollTop();

			if ((scrollTop > offset.top - topOffset) && (scrollTop < offset.top - topOffset + $(this).height())) {
				floatingHeaderRow.css("visibility", "visible");
				floatingHeaderRow.css("top", topOffset + "px");

				// Copy cell widths from original header
				$("th", floatingHeaderRow).each(function(index) {
					var cellWidth = parseInt($("th", originalHeaderRow).eq(index).css('width'), 10);
					$(this).css('width', cellWidth + 2 + "px");
				});

				// Copy row width from whole table
				floatingHeaderRow.css("width", $(this).css("width"));
			}
			else {
				floatingHeaderRow.css("visibility", "hidden");
				floatingHeaderRow.css("top", "0px");
			}
		});
	}

	$(document).ready(function() {
		$(window).scroll(UpdateTableHeaders);
		$(window).resize(UpdateTableHeaders);
		UpdateTableHeaders();
	});
}())
